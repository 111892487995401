export default [
  {
    label: 'Gift card',
    value: 'GIFT_CARD'
  },
  {
    label: 'Deal of the day',
    value: 'DOTD'
  },
  {
    label: 'Post text',
    value: 'BANNED_POST_TEXT'
  },
  {
    label: 'Not posting this deal list to page deals',
    value: 'NOT_POSTING_DEAL_LISTS'
  },
  {
    label: 'Not posting this brand to page deals',
    value: 'NOT_POSTING_BRAND'
  },
  {
    label: 'IP Address',
    value: 'BANNED_IP_ADDRESS'
  },
  {
    label: 'Post image',
    value: 'BANNED_POST_IMAGE'
  },
  {
    label: 'Email domain',
    value: 'BANNED_EMAIL_DOMAIN'
  },
  {
    label: 'Banned extra deal',
    value: 'BANNED_EXTRA_DEAL'
  },
  {
    label: 'Email TLD',
    value: 'BANNED_EMAIL_TLD'
  },
  {
    label: 'Banned auto add brand',
    value: 'BANNED_AUTO_ADD_BRANDS'
  },
  {
    label: 'Banned deal of the day email title brands',
    value: 'BANNED_DOTD_EMAIL_TITLE_BRAND'
  },
  {
    // this is for things like Gerber baby food vs Gerber knives
    label: 'Banned similar brands',
    value: 'BANNED_SIMILAR_BRANDS'
  },
  {
    label: 'Banned deal promotion',
    value: 'BANNED_DEAL_PROMOTION'
  }
];
